@import "../../base.scss";

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.container {
  .description {
    white-space: pre-line;
  }

  .resolver {
    opacity: 0;
    display: none;
    margin-top: 20px;
  }

  .nextProfile {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input[type="button"] {
    height: 60px;
    width: 200px;
    font-size: 1.3em;
    background-color: $dark;
    color: $txt-green;
    border: 1px solid #b6b6b6;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 2px 2px 2px 2px $dark;

    &:active {
      box-shadow: none;
    }
  }

  .hint {
    margin-top: 30%;
    color: transparent;
    text-align: right;
  }

  .end {
    text-align: center;

    p {
      font-size: 2em;
    }
  }

  .fadeIn {
    display: block;
    animation-name: fade;
    animation-duration: 3s;
    animation-fill-mode: forwards;
  }
}
