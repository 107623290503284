body {
  background-color: #2f3951;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
}

#root {
  flex: 1;
  display: flex;
}
