@import "../../base.scss";

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  display: flex;
  background-color: $bg-dark;
  color: #ffffff;
  padding-top: 3px;
  font-size: 1.3em;
  height: 100px;
  border-bottom: 1px solid $txt-green;
  position: relative;

  .leftSpace {
    display: none;
    flex: 1;

    @media (min-width: $small) {
      display: flex;
    }
  }

  .agent {
    flex: 3;
    margin: auto;
    display: flex;
    align-items: center;
    color: $txt-green;
  }

  .targets {
    flex: 1;
    display: none;
    padding: 0;
    margin: 0;
    flex-direction: row-reverse;
    align-self: flex-end;

    .photos {
      display: flex;
      flex-direction: row-reverse;
      align-self: flex-end;

      a {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: flex-end;
        border-radius: 10px 10px 0 0;

        &:hover {
          background-color: $bg-med;
        }

        img {
          margin: 0 10px;
          cursor: pointer;
        }
      }
    }

    @media (min-width: $small) {
      display: flex;
    }
  }

  .blink {
    visibility: visible;
    animation-name: blink;
    animation-iteration-count: infinite;
    animation-duration: 1s;
    margin: 0 3px;
  }
}
