@import "../../base.scss";

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .code {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40vw;

    img {
      height: 25px;
      cursor: pointer;
      margin-left: 10px;
    }
    input {
      height: 50px;
      font-size: 2em;
      color: $dark;
      padding: 2px;
      max-width: 100%;
    }
  }

  .success {
    visibility: hidden;
    font-variant: small-caps;
    color: $txt-green;
    font-size: 1.5em;
    animation-name: blink;
    animation-iteration-count: infinite;
    animation-duration: 2s;
    margin-top: 3px;

    &.visible {
      visibility: visible;
    }
  }

  &.fullscreen {
    width: 100%;
    height: 100vh;
    background-color: $bg-dark;

    .code {
      width: 60vw;

      img {
        height: 40px;
      }

      input {
        height: 80px;
        font-size: 3em;
        padding: 3px;
      }
    }

    .success {
      font-size: 2.5em;
    }
  }
}
