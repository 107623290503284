@import "../../base.scss";

.container {
  z-index: 2;

  .avatar {
    border-radius: 100%;
    border: 1px solid #ffffff;
    background-image: url(../../assets/avatar.png);
    background-size: cover;
    width: 120px;
    height: 120px;
    box-shadow: 0px 0px 4px 2px $dark;
    border: 2px solid $dark;
  }
}
