@import "../base.scss";

.layout {
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  margin: 0 auto;
  flex: 1;

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    background: $bg-med;
    color: white;
    .page {
      flex: 1;
      width: 50vw;
      margin: 30px auto;
    }
  }
}
