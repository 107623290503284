@import "../../base.scss";

.container {
  .backButton {
    color: $txt-green;
  }

  .infos {
    font-style: italic;
  }

  .description {
    white-space: pre-line;
  }
}
