@import "../../base.scss";

.container {
  footer {
    display: flex;
    align-items: center;
    margin: auto;
    border-top: 1px solid $txt-green;
    color: $dark;
    height: 100px;
    width: 50vw;
    justify-content: center;
    color: $txt-green;
  }
}
