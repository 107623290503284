@import "../../base.scss";

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.container {
  display: flex;
  align-items: flex-end;
  margin: 15px auto;

  .photo {
    margin: 0 15px 0 0;
    padding: 0;
    img {
      border: 1px solid #ffffff;
      border-radius: 0px;
      background-color: $bg-dark;
      box-shadow: 1px 1px 2px 2px $dark;
    }

    &.fadeIn {
      opacity: 0;
      animation-name: fade;
      animation-duration: 3s;
      animation-fill-mode: forwards;
    }
  }

  .name {
    font-size: 1.3em;
    padding-bottom: 10px;
  }
}
